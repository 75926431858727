<template>
  <v-container id="regular-tables" fluid tag="section">


    <base-material-card color="success" icon="mdi-clipboard-plus" v-if="usertype == 'admin'" title="Add New Solution"
      class="px-5 py-3">

      <v-container class="py-5">
        <v-row>
          <v-progress-linear :active="loading" :indeterminate="loading" color="primary"></v-progress-linear>


          <v-col cols="12" md="6">
            <v-text-field label="Solution Name" class="purple-input" outlined dense v-model="Finalitem.SolutionTitle" />
          </v-col>
          <v-col cols="12" md="6">
            <v-select :items="Types" v-model="Finalitem.Types" label="Main Type" outlined dense></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-textarea label="Sub Title" class="purple-input" outlined dense v-model="Finalitem.Subtitle" />
          </v-col>

          <v-col cols="12" md="6">
            <v-textarea label="Discription" class="purple-input" outlined dense v-model="Finalitem.SolutionDesc" />
          </v-col>


          <v-col cols="12" md="6">
            <base-material-card color="success" title="Why Use" class="px-5 py-3">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="WhyUse" label="Description" class="purple-input" outlined dense />
                  <v-btn color="primary" dark class="mb-2" @click="AddNewWhyUse">
                    Add New Item
                  </v-btn>


                </v-col>

                <v-col cols="12" md="6">

                  <template>
                    <v-file-input label="Why Use Image" v-model="Solution.WhyUseImage" outlined prepend-icon="mdi-camera"
                      dense></v-file-input>
                  </template>

                </v-col>

              </v-row>




              <template>
                <v-data-table :headers="headerWhyUse" :items="myItemsTransformed" item-key="name" class="elevation-1">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Why Use List</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon small @click="deleteWhyUse(item)">
                      mdi-delete
                    </v-icon>
                  </template>

                </v-data-table>
              </template>


            </base-material-card>


          </v-col>

          <v-col cols="12" md="6">

            <input type="file" style="display: none" ref="fileinput3" accept="image/*"
              @change="onEditfilepicked_BM($event, editedItem)">


            <v-data-table v-model="selected" :headers="BM_headers" :items="BusinessModule" :single-select="singleSelect"
              item-key="name" class="elevation-1">
              <template v-slot:top>
                <v-dialog v-model="BusinessModuledialog" width="500">



                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" color="blue-grey" class="ma-2 white--text">Add New Business Module
                      <v-icon right dark>mdi-cloud-upload</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      New Business Module
                    </v-card-title>

                    <v-row>
                      <v-col cols="1" md="1" />
                      <v-col cols="5" md="5">
                        <v-text-field label="Business Module" v-model="Dialog_BM.BM_text" :rules="[rules.required]"
                          class="purple-input pa-md-4 mx-lg-auto" outlined dense />
                      </v-col>

                      <v-col cols="5" md="5">
                        <img @click="onEditPickFile_BM" class="img" :src="Dialog_BM.BM_Image" width="64" height="64">
                      </v-col>

                    </v-row>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="AddBMToList">
                        I accept
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>


              </template>


              <template v-slot:item.ItemName="{ item }">
                <v-img width=75 height=75 :src="item.Show">
                </v-img>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-icon small @click="deleteBMItem(item)">
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                  Reset
                </v-btn>
              </template>


            </v-data-table>

          </v-col>

          <v-col cols="12" md="12">
            <div>
              <template>
                <v-file-input label="Solution Logo" outlined v-model="Solution.SolutionLogo" prepend-icon="mdi-camera"
                  dense></v-file-input>
              </template>

              <template>
                <v-file-input label="Solution background" v-model="Solution.SolutionBackground" outlined
                  prepend-icon="mdi-camera" dense></v-file-input>
              </template>

              <v-file-input label="Upload PDF" v-model="Solution.MainPDF" outlined accept="application/pdf"
                dense></v-file-input>

            </div>
            <div class="control" style="text-align: left;">


              <input type="file" style="display: none" ref="fileinput" accept="image/*" @change="onfilepicked">
            </div>



          </v-col>

        </v-row>
        <template>
          <v-btn color="success" class="mr-0" @click="InsertItemToDB">
            Add Product
          </v-btn>
        </template>
      </v-container>
    </base-material-card>


    <base-material-card icon="mdi-clipboard-text" title="Items View" class="px-5 py-3">
      <v-data-table :headers="headers" :items="Solutions" sort-by="calories" class="elevation-1">
        <template v-slot:item.IsActive="{ item }">
          <v-chip :color="getColor(item.Inactive)" dark>
            {{ item.Inactive }}
          </v-chip>
        </template>

        <template v-slot:item.type="{ item }">
          <label :id="item.type + Products.indexOf(item)"> {{ getcategoryName(item.type, Products.indexOf(item)) }}</label>
        </template>


        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Solutions List</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="85%">


              <div style="background: white;">




                <v-container class="py-5">
                  <v-row>
                    <v-progress-linear :active="loading" :indeterminate="loading" color="primary"></v-progress-linear>


                    <v-col cols="12" md="6">
                      <v-text-field label="Solution Name" class="purple-input" outlined dense
                        v-model="editedItem.SolutionTitle" />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select :items="Types" v-model="editedItem.Type" label="Main Type" outlined dense></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-textarea label="Sub Title" class="purple-input" outlined dense v-model="editedItem.Subtitle" />
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-textarea label="Discription" class="purple-input" outlined dense
                        v-model="editedItem.SolutionDesc" />
                    </v-col>


                    <v-col cols="12" md="6">
                      <base-material-card color="success" title="Why Use" class="px-5 py-3">
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field v-model="WhyUse" label="Description" class="purple-input" outlined dense />
                            <v-btn color="primary" dark class="mb-2" @click="AddNewWhyUse_Edit">
                              Add New Item
                            </v-btn>


                          </v-col>

                          <v-col cols="12" md="6">

                            <template>
                              <v-file-input label="Why Use Image" v-model="editedItem.WhyUseImage" outlined
                                prepend-icon="mdi-camera" dense></v-file-input>
                            </template>

                          </v-col>

                        </v-row>




                        <template>
                          <v-data-table :headers="headerWhyUse" :items="editedItem.WhyUse" class="elevation-1">
                            <template v-slot:top>
                              <v-toolbar flat>
                                <v-toolbar-title>Why Use List</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                              </v-toolbar>
                            </template>
                            <template v-slot:item.item="{ item }">
                              {{ item }}
                            </template>
                            <template v-slot:item.actions="{ item }">
                              <v-icon small @click="deleteEditWhyUse(item)">
                                mdi-delete
                              </v-icon>
                            </template>

                          </v-data-table>
                        </template>


                      </base-material-card>


                    </v-col>

                    <v-col cols="12" md="6">

                      <input type="file" style="display: none" ref="fileinput3" accept="image/*"
                        @change="onEditfilepicked_BM($event, editedItem)">


                      <v-data-table v-model="selected" :headers="BM_Edit_headers" :items="editedItem.BusinessModel"
                        :single-select="singleSelect" item-key="name" class="elevation-1">
                        <template v-slot:top>
                          <v-dialog v-model="BusinessModuledialog" width="500">



                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" color="blue-grey" class="ma-2 white--text">Add New Business
                                Module
                                <v-icon right dark>mdi-cloud-upload</v-icon>
                              </v-btn>
                            </template>

                            <v-card>
                              <v-card-title class="text-h5 grey lighten-2">
                                New Business Module
                              </v-card-title>

                              <v-row>
                                <v-col cols="1" md="1" />
                                <v-col cols="5" md="5">
                                  <v-text-field label="Business Module" v-model="Dialog_BM.BM_text"
                                    :rules="[rules.required]" class="purple-input pa-md-4 mx-lg-auto" outlined dense />
                                </v-col>

                                <v-col cols="5" md="5">
                                  <img @click="onEditPickFile_BM" class="img" :src="Dialog_BM.BM_Image" width="64"
                                    height="64">
                                </v-col>

                              </v-row>

                              <v-divider></v-divider>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="editedBMToList">
                                  I accept
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>


                        </template>


                        <template v-slot:item.Image="{ item }">
                          <v-img width=75 height=75 :src="item.Image">
                          </v-img>
                        </template>

                        <template v-slot:item.actions="{ item }">
                          <v-icon small @click="deleteBMItem(item)">
                            mdi-delete
                          </v-icon>
                        </template>
                        <template v-slot:no-data>
                          <v-btn color="primary" @click="initialize">
                            Reset
                          </v-btn>
                        </template>


                      </v-data-table>

                    </v-col>

                    <v-col cols="12" md="12">
                      <div>
                        <template>
                          <v-file-input label="Solution Logo" outlined v-model="editedItem.Logo" prepend-icon="mdi-camera"
                            dense></v-file-input>
                        </template>

                        <template>
                          <v-file-input label="Solution background" v-model="editedItem.BackTitle" outlined
                            prepend-icon="mdi-camera" dense></v-file-input>
                        </template>

                        <v-file-input label="Upload PDF New" v-model="editedItem.PDFURL" outlined accept="application/pdf"
                          dense></v-file-input>

                      </div>
                      <div class="control" style="text-align: left;">


                        <input type="file" style="display: none" ref="fileinput" accept="image/*" @change="onfilepicked">
                      </div>



                    </v-col>

                  </v-row>
                  <template>
                    <v-btn color="success" class="mr-0" @click="UpdateItemToDB">
                      Update Solution
                    </v-btn>
                  </template>
                </v-container>
              </div>

              </div>>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">
            Reset
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>


    <div class="py-3" />

    <v-snackbar v-model="form.snackbar" :multi-line="multiLine">
      {{ form.snakMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="form.snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>

import { db, fb } from '@/db'
import { mapMutations, mapState } from 'vuex'

export default {
  data() {
    return {
      loading: false,
      BusinessModuledialog: false,
      ItemDetails: [],
      collapseDuration: 0,
      Solution: {
        WhyUse: [],
        WhyUseImage: '',
        SolutionLogo: null,
        SolutionBackground: null
      },
      rules: {
        required: value => !!value || 'Required.',
      },
      headers: [
        {
          text: 'Solution Name',
          align: 'start',
          sortable: false,
          value: 'SolutionTitle',
        },
        { text: 'Type', value: 'Type' },
        { text: 'Order In Type', value: 'Order' },
        { text: 'IsActive', value: 'Inactive' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      BM_headers: [
        {
          text: 'Icon',
          align: 'start',
          sortable: false,
          value: 'ItemName',
        },
        { text: 'Value', value: 'text' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      BM_Edit_headers: [
        {
          text: 'Icon',
          align: 'start',
          sortable: false,
          value: 'Image',
        },
        { text: 'Value', value: 'Desc' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],

      BusinessModule: [
      ],
      selected: [
        {
          Desc: 'Distribution',
          Image: 'https://firebasestorage.googleapis.com/v0/b/incubewebsite.appspot.com/o/Products%2FBModel%2Fappoinmentmangment.png?alt=media&token=44109931-e66e-4740-a0f1-01af0e2dc0b4'
        }
      ],
      MainItem: [],
      components: [],

      form: {
        Images: [],

        snackbar: false,
        snakMsg: "Uploaded success"
      },

      Dialog_BM: {
        BM_text: '',
        BM_Image: 'http://simpleicon.com/wp-content/uploads/cloud-upload-2.png',
        BM_Show: '',
      },
      Finalitem: {
        SolutionDesc: '',
      },
      WhyUse: '',
      Types: ['BlockChain', 'SolutionsInaCube', 'SupplyChain'],
      ImageFire: [],
      dialog: false,
      dialogDelete: false,
      search: '',
      calories: '',
      editedIndex: -1,
      editedItem: {
        ItemName: '',
        ItemCost: 0,
        ItemUnit: 0,
        Is_Decoration: 0,
        AdditionalCost: 0,
      },
      defaultItem: {
        ItemName: '',
        ItemCost: 0,
        ItemUnit: 'g',
        ItemQTY: 0,
        Is_Decoration: 0,
        AdditionalCost: 0,
      },
    }
  },
  computed: {
    headerWhyUse() {
      return [
        {
          text: 'Description',
          align: 'start',
          value: 'item',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ]
    },
    myItemsTransformed() {
      return this.Solution.WhyUse.map(item => ({ item }));
    },
    Partner() {
      return this.$store.state.AllDistribution
    },
    usertype() {
      return this.$store.state.userType
    },
    Solutions() {
      return this.$store.state.Solutions
    },
    formTitle() {
      return 'Solutions'
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  methods: {
    AddNewWhyUse() {
      this.Solution.WhyUse.push(this.WhyUse);
      this.WhyUse = '';
    },
    AddNewWhyUse_Edit() {
      this.editedItem.WhyUse.push(this.WhyUse);
      this.WhyUse = '';
    },

    toggleDetails(item, index) {
      this.$set(this.MainItem[index], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0 })
    },
    deleteWhyUse(item) {

      this.Solution.WhyUse.splice(this.Solution.WhyUse.indexOf(item.item), 1)
    },
    deleteEditWhyUse(item) {

      this.editedItem.WhyUse.splice(this.editedItem.WhyUse.indexOf(item.item), 1)
    },

    getColor(IsActive) {

      if (IsActive) return 'green'
      else return 'red'

    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    },

    deleteImageItem(Document, Index) {

      Document.Images.splice(Index, 1)
      var context = this;
      return db.collection('Product')
        .doc(Document.ID)
        .update({ "Images": Document.Images })
        .then(_ => {
          context.form.snackbar = true
          context.form.snakMsg = "Delete Successfully"

        })
    },
    UpdateNewProductImage(Document, ImagePath) {

      var storageRef = fb.storage().ref('ProductPic/' + Document.ID + '/' + this.uuidv4())
      let UploadTask = storageRef.put(ImagePath);


      UploadTask.on('state_changed', async function (snapshot) {
      }, function (error) {

      }, function () {

        UploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {

          Document.Images.push(downloadURL)
          return db.collection('Product')
            .doc(Document.ID)
            .update({ "Images": Document.Images })
            .then(_ => {
              context.form.snakMsg = "Upload Successfully"
              context.form.snackbar = true
            })

        })
      })
    },
    UpdateItemToDB() {
      debugger
      console.log(this.editedItem.ID)
      var context = this;
      this.loading = true;
      var Sol_ID = context.editedItem.ID;
      db.collection('Solutions').doc(context.editedItem.ID)
        .update({
          'WhyUse': this.editedItem.WhyUse, 'SolutionDesc': this.editedItem.SolutionDesc,
          'SolutionTitle': this.editedItem.SolutionTitle, 'Subtitle': this.editedItem.Subtitle,
          'Type': this.editedItem.Type
        })
        .then(docRef => {

          // When Success Then Upload PDF For Solution
          var uuid = require("uuid");
          if (typeof context.editedItem.PDFURL !== 'string') {
            var storageRef1 = fb.storage().ref('Solutions/' + context.editedItem.ID + '/' + uuid.v4())
            let UploadTask1 = storageRef1.put(context.editedItem.PDFURL);
            UploadTask1.on('state_changed', async function (snapshot) {
            }, function (error) {

            }, function () {
              UploadTask1.snapshot.ref.getDownloadURL().then(function (downloadURL) {

                return db.collection('Solutions')
                  .doc(Sol_ID)
                  .update({ "PDFURL": downloadURL })
              })
            })
          }

          // When Success Then Upload Logo Image For Solution     

          if (typeof context.editedItem.Logo !== 'string') {

            var storageRef2 = fb.storage().ref('Solutions/' + context.editedItem.ID + '/' + uuid.v4())
            let UploadTask2 = storageRef2.put(context.editedItem.Logo);
            UploadTask2.on('state_changed', async function (snapshot) {
            }, function (error) {

            }, function () {
              UploadTask2.snapshot.ref.getDownloadURL().then(function (downloadURL) {

                return db.collection('Solutions')
                  .doc(Sol_ID)
                  .update({ "Logo": downloadURL })
              })
            })
          }


          if (typeof context.editedItem.BackTitle !== 'string') {

            var storageRef3 = fb.storage().ref('Solutions/' + context.Solution.ID + '/' + uuid.v4())
            let UploadTask3 = storageRef3.put(context.editedItem.BackTitle);
            UploadTask3.on('state_changed', async function (snapshot) {
            }, function (error) {

            }, function () {
              UploadTask3.snapshot.ref.getDownloadURL().then(function (downloadURL) {

                return db.collection('Solutions')
                  .doc(Sol_ID)
                  .update({ "BackTitle": downloadURL })
              })
            })
          }

          if (typeof context.editedItem.WhyUseImage !== 'string') {
            var storageRef4 = fb.storage().ref('Solutions/' + context.editedItem.ID + '/' + uuid.v4())
            let UploadTask4 = storageRef4.put(context.editedItem.WhyUseImage);
            UploadTask4.on('state_changed', async function (snapshot) {
            }, function (error) {

            }, function () {
              UploadTask4.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                // When Success Then Upload WhyUseImage For Solution     

                return db.collection('Solutions')
                  .doc(Sol_ID)
                  .update({ "WhyUseImage": downloadURL })
              })
            })
          }

          var count = 0
          for (var x = 0; x < context.editedItem.BusinessModel.length; x++) {

            if (typeof context.editedItem.BusinessModel[x].Image === 'string')
              continue;

            var storageRef = fb.storage().ref('Solutions/' + context.editedItem.ID + '/' + uuid.v4())

            let UploadTask = storageRef.put(context.editedItem.BusinessModule[x].Image);

            UploadTask.on('state_changed', async function (snapshot) {
            }, function (error) {

            }, function () {
              UploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                console.log("Photo num " + x + " Uploaded done : " + downloadURL)
                context.BusinessModule[count].Image = ''
                context.BusinessModule[count].Image = downloadURL
                count++;
                if (count == context.BusinessModule.length) {
                  return db.collection('Solutions')
                    .doc(docRef.id)
                    .update({ "BusinessModel": context.BusinessModule })
                    .then(docRef => {
                      context.loading = false;
                      alert('Solutions Updated Successfully ')


                    })
                }
              })
            })
          }
          alert('Solutions Updated Successfully ')
          context.close()
        })
    },
    InsertItemToDB() {
      var context = this;
      this.loading = true;

      db.collection('Solutions')
        .add({ ...this.Finalitem, 'WhyUse': this.Solution.WhyUse, 'Order': 99, 'InActive': false, Type: this.Finalitem.Types })
        .then(docRef => {
          // When Success Then Upload PDF For Solution
          var uuid = require("uuid");
          var storageRef1 = fb.storage().ref('Solutions/' + docRef.id + '/' + uuid.v4())
          let UploadTask1 = storageRef1.put(context.Solution.MainPDF);
          context.Solution.DocID = docRef.id
          UploadTask1.on('state_changed', async function (snapshot) {
          }, function (error) {

          }, function () {
            UploadTask1.snapshot.ref.getDownloadURL().then(function (downloadURL) {

              return db.collection('Solutions')
                .doc(Sol_ID)
                .update({ "PDFURL": downloadURL, "ID": context.Solution.DocID })
                .then(_ => {
                  // When Success Then Upload Logo Image For Solution     

                  var uuid = require("uuid");
                  var storageRef1 = fb.storage().ref('Solutions/' + context.Solution.DocID + '/' + uuid.v4())
                  let UploadTask1 = storageRef1.put(context.Solution.SolutionLogo);
                  UploadTask1.on('state_changed', async function (snapshot) {
                  }, function (error) {

                  }, function () {
                    UploadTask1.snapshot.ref.getDownloadURL().then(function (downloadURL) {

                      return db.collection('Solutions')
                        .doc(Sol_ID)
                        .update({ "Logo": downloadURL })
                        .then(_ => {
                          // When Success Then Upload BackGround Image For Solution     

                          var uuid = require("uuid");
                          var storageRef1 = fb.storage().ref('Solutions/' + context.Solution.DocID + '/' + uuid.v4())
                          let UploadTask1 = storageRef1.put(context.Solution.SolutionBackground);
                          UploadTask1.on('state_changed', async function (snapshot) {
                          }, function (error) {

                          }, function () {
                            UploadTask1.snapshot.ref.getDownloadURL().then(function (downloadURL) {

                              return db.collection('Solutions')
                                .doc(Sol_ID)
                                .update({ "BackTitle": downloadURL })
                                .then(_ => {
                                  // When Success Then Upload WhyUseImage For Solution     

                                  var uuid = require("uuid");
                                  var storageRef1 = fb.storage().ref('Solutions/' + context.Solution.DocID + '/' + uuid.v4())
                                  let UploadTask1 = storageRef1.put(context.Solution.WhyUseImage);
                                  UploadTask1.on('state_changed', async function (snapshot) {
                                  }, function (error) {

                                  }, function () {
                                    UploadTask1.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                                      // When Success Then Upload WhyUseImage For Solution     

                                      return db.collection('Solutions')
                                        .doc(Sol_ID)
                                        .update({ "WhyUseImage": downloadURL })
                                        .then(_ => {
                                          var count = 0
                                          for (var x = 0; x < context.BusinessModule.length; x++) {
                                            var storageRef = fb.storage().ref('Solutions/' + docRef.id + '/' + uuid.v4())

                                            let UploadTask = storageRef.put(context.BusinessModule[x].Image);

                                            UploadTask.on('state_changed', async function (snapshot) {
                                            }, function (error) {

                                            }, function () {

                                              UploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                                                console.log("Photo num " + x + " Uploaded done : " + downloadURL)
                                                context.BusinessModule[count].Image = ''
                                                context.BusinessModule[count].Image = downloadURL
                                                count++;
                                                if (count == context.BusinessModule.length) {

                                                  return db.collection('Solutions')
                                                    .doc(Sol_ID)
                                                    .update({ "BusinessModel": context.BusinessModule })
                                                    .then(docRef => {
                                                      context.loading = false;
                                                      alert('Solutions Added Successfully ')


                                                    })
                                                }
                                              })
                                            })
                                          }


                                        })
                                    })
                                  })

                                })
                            })
                          })

                        })
                    })
                  })

                })
            })
          })

        })
    },
    rowClick(item, row) {
      var found = false;
      for (var i = 0; i < this.selected.length; i++) {
        if (this.selected[i].ItemName == item.ItemName) {
          found = true;
          break;
        }
      }
      if (!found) {
        var newitem = Object.assign({}, item);
        this.Finalitem.Cost = this.Finalitem.Cost + parseFloat(newitem.ItemCost) + parseFloat(newitem.AdditionalCost);
        this.selected.push(newitem);
      } else {
        alert("Item Already Used");
      }

    },
    filterOnlyCapsText(value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
    },
    editItem(item) {
      debugger
      this.editedIndex = this.Solutions.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.id = item.id
      this.dialog = true
    },

    deleteItem(item) {

      this.editedIndex = this.Solutions.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteBMItem(item) {

      this.BusinessModule.splice(this.BusinessModule.indexOf(item), 1);
    },
    deleteMainItem(item, index) {

      var context = this;
      if (confirm('Are you sure you want to delete this ?')) {

      }
    },
    deleteItemConfirm() {

      db.collection('Solutions').doc(this.editedItem.ID)
        .delete().then(_ => {
          alert("Item Was Deleted ...")
          context.MainItem.splice(index);
        })

      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    BM_close() {
      this.BusinessModuledialog = false
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {

      if (this.editedIndex > -1) {
        db.collection('Product').doc(this.editedItem.ID)
          .update(this.editedItem).then(_ => {
            alert("Product Was Updated ...")
          })
      } else {
        this.selected.push(this.editedItem)
      }
      this.close()


    },

    onPickFile() {
      this.$refs.fileinput.click();
    },
    onEditPickFile() {
      this.$refs.fileinput2.click();
    },
    onEditPickFile_BM() {
      this.$refs.fileinput3.click();
    },
    onPickFile_WhyUse() {
      this.$refs.fileinput4.click();
    },
    ondelete(index) {
      this.form.Images.splice(index, 1);

    },
    onfilepicked(event) {
      const files = event.target.files
      let filename = files[0].name
      if (filename.lastIndexOf('.') <= 0) {
        return alert('please add a valid file !')
      }

      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.form.Images.push(reader.result)
      })
      reader.readAsDataURL(files[0])
      this.ImageFire.push(files[0])
    },

    onEditfilepicked(event, document) {

      const files = event.target.files
      let filename = files[0].name
      if (filename.lastIndexOf('.') <= 0) {
        return alert('please add a valid file !')
      }




      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.Dialog_BM.BM_ImageShow = reader.result
      })
      reader.readAsDataURL(files[0])
      this.Dialog_BM.BM_Image = files[0]
    },

    onEditfilepicked_BM(event, document) {

      const files = event.target.files
      let filename = files[0].name
      if (filename.lastIndexOf('.') <= 0) {
        return alert('please add a valid file !')
      }

      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.Dialog_BM.BM_ImageShow = reader.result
      })
      reader.readAsDataURL(files[0])
      this.Dialog_BM.BM_Image = files[0]
    },

    onfilepicked_WhyUse(event, document) {

      const files = event.target.files
      let filename = files[0].name
      if (filename.lastIndexOf('.') <= 0) {
        return alert('please add a valid file !')
      }

      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.Solution.WhyUseImage = reader.result
      })
      reader.readAsDataURL(files[0])

    },



    getRowItem() {
      db
        .collection('Items')
        .doc('RowItem')
        .collection('Material')
        .get()
        .then(snapshots => {
          if (snapshots.docs.length === 0) { return [] }
          const exchange = snapshots.docs.map(doc => ({ ...doc.data() }))
          this.setdesserts(BusinessModule)

        })
    },

    AddBMToList() {

      if (this.Dialog_BM.BM_text === "") {
        return
      }
      this.BusinessModule.push({
        Desc: this.Dialog_BM.BM_text,
        Image: this.Dialog_BM.BM_Image, Show: this.Dialog_BM.BM_ImageShow
      })
      this.Dialog_BM.BM_text = ''
      this.Dialog_BM.BM_Image = 'http://simpleicon.com/wp-content/uploads/cloud-upload-2.png'
      this.BusinessModuledialog = false


    },
    editedBMToList() {

      if (this.Dialog_BM.BM_text === "") {
        return
      }
      this.BusinessModule.push({
        Desc: this.Dialog_BM.BM_text,
        Image: this.Dialog_BM.BM_Image, Show: this.Dialog_BM.BM_ImageShow
      })
      this.Dialog_BM.BM_text = ''
      this.Dialog_BM.BM_Image = 'http://simpleicon.com/wp-content/uploads/cloud-upload-2.png'
      this.BusinessModuledialog = false


    },
    set_component(components) {
      this.components = components
    },
    setdesserts(BusinessModule) {

      this.BusinessModule = BusinessModule
    },
    customSearch(value) {

      if (Array.isArray(value)) {
        return value.some(item => Object.values(item).some(v => v && v.toString().toLowerCase().includes(search)))
      }
      return Object.values(item).some(v => v && v.toString().toLowerCase().includes(search))
    }
  },
  created() {
    this.$store.dispatch('bindAllDistribution', 'null')
    this.$store.dispatch('bindProduct')
    this.$store.dispatch('bindSolutions')
    this.getRowItem()
    this.getMainItem()
    this.getTypeofItem()
  }
}
</script>


<style lang="scss">
.tim-note {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  margin-left: 20px;
  width: 260px;
}
</style>
